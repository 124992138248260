<template>
<div class="page-home" :class="isDialog?'ispc':'ismobile'">
    <div class="form-container">
      <div class="submit-head">
        <img
          class="form-head"
          src="~@/assets/images/formHead@2x.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
    <applyForm></applyForm>
  </div>
</template>

<script>
import applyForm from '@/components/applyForm';
import utils from '@/utils';

export default {
  components: {
    applyForm
  },
  data() {
    return {
      isDialog: !utils.isMobile()
    };
  },
};
</script>

<style lang="scss" scoped>
@function px2rem($value, $ratio) {
  @return $value / $ratio + 0rem;
}
.row-reverse {
  flex-direction: row-reverse;
}
.column-reverse {
  flex-direction: column-reverse;
}

.btn-apply {
  background: #5769ff;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: #6f57ff;
  }
}
.submit-head {
  display: flex;
  align-items: center;
  padding-left: 1.67rem;
  width: 100%;
  height: 6.75rem;
  background: #f3f5ff;
  border-bottom: 1px solid rgba($color: #5769ff, $alpha: 0.2);
  .form-head {
    width: 22.33rem;
  }
}

</style>
